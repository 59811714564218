import React, { Suspense, lazy } from "react";
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import Navbar from "./component/navbar";

import './App.css'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThemeProvider, createTheme, responsiveFontSizes, Box, CircularProgress } from "@mui/material";
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import CookieBanner from "./component/cookiebanner/CookieBanner";
import ScrollToHashElement from "./component/ScrollToHashElement";
import { ParallaxProvider } from 'react-scroll-parallax';


let theme = createTheme({
  palette: {
    primary: {
      main: '#002c6aff',
    },
    secondary: {
      main: '#ff0077',
    },
  },
});

const HomeView = lazy(() => import('./page/Home'));
// const GetAQuoteMSP = lazy(() => import('./page/GetAQuoteMSPPage'));
const View404 = lazy(() => import('./page/View404'));
const DetailsMSP = lazy(() => import('./page/ServiceDetailsMSP'));
const UserConditionsPage = lazy(() => import('./page/UserConditionsPage'));
const DetailsSoftware = lazy(() => import('./page/ServiceDetailsSoftwareDevelopment'));

const App = (props) => {
  theme.typography.h1 = {
    ...theme.typography.h1,
    "fontFamily": "\"Raleway\", sans-serif",
  };

  theme.typography.h2 = {
    ...theme.typography.h2,
    "fontFamily": "\"Raleway\", sans-serif",
  };

  theme.typography.h3 = {
    ...theme.typography.h3,
    "fontFamily": "\"Raleway\", sans-serif",
  };

  theme.typography.h4 = {
    ...theme.typography.h4,
    "fontFamily": "\"Raleway\", sans-serif",
  };

  theme.typography.h5 = {
    ...theme.typography.h5,
    "fontFamily": "\"Raleway\", sans-serif",
  };

  theme.typography.h6 = {
    ...theme.typography.h6,
    "fontFamily": "\"Raleway\", sans-serif",
  };

  theme.typography.subtitle1 = {
    ...theme.typography.subtitle1,
    "fontFamily": "\"Raleway\", sans-serif",
  };

  theme.typography.subtitle2 = {
    ...theme.typography.subtitle2,
    "fontFamily": "\"Raleway\", sans-serif",
  };

  theme.typography.body1 = {
    ...theme.typography.body1,
    "fontFamily": "\"Raleway\", sans-serif",
  };

  theme.typography.body2 = {
    ...theme.typography.body2,
    "fontFamily": "\"Raleway\", sans-serif",
  };


  const history = createBrowserHistory();

  const renderLoading = () => {
    return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress size={100} />
    </Box>
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcsYKsnAAAAAJ_vHiP1H-HQC8biCaBDOeJ7re2B" >
      <ThemeProvider theme={theme}>
        <Suspense fallback={renderLoading()}>
          <ParallaxProvider>
            <BrowserRouter history={history}>
              <Navbar />
              <CookieBanner />
              <Routes>
                <Route exact path="/" element={<HomeView />} />
                <Route exact path="/msp" element={<DetailsMSP />} />
                {/* <Route exact path="/software-development" element={<DetailsSoftware />} /> */}
                <Route exact path="/conditions" element={<UserConditionsPage />} />
                <Route path="*" element={<View404 />} />
              </Routes>
            </BrowserRouter>
          </ParallaxProvider>
        </Suspense>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
