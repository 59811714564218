import React, { useState, useEffect, useRef } from "react";

const useCookieConsent = () => {

    const [cookieConsent, setCookieConsent] = useState(undefined)

    const cookieConsentRef = useRef(cookieConsent)
    const setCookieConsentRef = (data) => {
        cookieConsentRef.current = data
        setCookieConsent(data)
    }

    useEffect(() => {
        const cookieConsent = localStorage.getItem("cookieConsent")
        if (cookieConsent === "true") {
            setCookieConsentRef(true)
        } else {
            setCookieConsentRef(false)
        }
    }, [])

    useEffect(() => {
        if (cookieConsentRef.current === true) {
            localStorage.setItem("cookieConsent", "true")
        } else {
            localStorage.setItem("cookieConsent", "false")
        }
    }, [cookieConsentRef.current])

    useEffect(() => {
        if (cookieConsentRef.current === true) {
            document.cookie = "cookieConsent=true; max-age=31536000; path=/"
        } else {
            document.cookie = "cookieConsent=false; max-age=31536000; path=/"
        }
    }, [cookieConsentRef.current])

    // useEffect(() => {
    //     if (cookieConsent) {
    //         window.navigator.cookieEnabled = true
    //     } else {
    //         window.navigator.cookieEnabled = false
    //     }
    // }, [cookieConsent])

    

    const handleCookieConsent = (value=true) => {
        setCookieConsentRef(value)
        if (value ) {
            localStorage.setItem("cookieConsent", value.toString())
        }
        else {
            localStorage.removeItem("cookieConsent")
        }
    }

    return { cookieConsent, handleCookieConsent }
    
}
export default useCookieConsent